import { useState } from 'react'
import { useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';

import { RootState } from '../../store/store';


export const useDeleteRecordType = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);
    const [success, setSuccess] = useState(false);

    const handleDelete = (id: number) => {
        setLoading(true);
        setErrors(false);
        setSuccess(false);

        setTimeout(async () => {
            await axios.delete(`${process.env.REACT_APP_API_URL}/record-types/${id}`, { params: { token: userData?.token } })
                .then(() => {
                    setErrors(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse);
                        }
                    }

                    setSuccess(false)
                })
                .finally(() => setLoading(false));
        }, 2000)
    }

    return { success, loading, errors, handleDelete }
}
