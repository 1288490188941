import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/store';

import { Spinner } from '../../../components/Spinner';

import { useCreateRecordsExcel } from '../../../hooks/record/useCreateRecordsExcel';

import excel_example from "../../../assets/excel-example.png";


export const RecordExcel = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const { loading: useCreateRecordExcelLoading, errors: useCreateRecordExcelErrors, response: useCreateRecordExcelResponse, handleCreate } = useCreateRecordsExcel();

    const [file, setFile] = useState<File>();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleFileUpload = (element: HTMLInputElement) => {
        setFile(undefined);
        const allowedExtensions = /(\.xlsx)$/i;

        if (!allowedExtensions.exec(element.value)) {
            alert('El archivo tiene que ser formato .xlsx');
            return;
        }

        if (element.files && element.files[0]) {
            const file = element.files[0];
            setFile(file);
        }
    }

    const handleSubmit = async () => {
        const formData = new FormData();

        formData.append('excel', file as File);
        formData.append('token', userData?.token as string);

        await handleCreate(formData);

        setShowSuccessModal(true);
    }

    // Titulo del tab
    useEffect(() => {
        document.title = `Subida de registros via Excel - ${process.env.REACT_APP_TITLE_PAGE}`
    }, []);

    if (useCreateRecordExcelLoading) {
        return <Spinner />
    }

    return (
        <>
            <div className={`bg24 rounded p-4`}>
                <div className='mb-2'>
                    <Form onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }}>
                        <h3 className="mb-4 text-center">Subida de registros via Excel</h3>

                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Control accept='.xlsx' type="file" onChange={(e) => handleFileUpload(e.currentTarget as HTMLInputElement)} />
                            <p className='m-2'>El archivo tiene que ser formato .xlsx</p>
                            <Button variant="primary" type="submit" className='w-100' disabled={!!!file}>Subir</Button>
                        </Form.Group>
                    </Form>
                </div>

                <div>
                    <h3>Como debe lucir mi archivo?</h3>

                    <div>
                        <img className='w-100 mb-2' style={{ maxWidth: '532px' }} src={excel_example} alt='excel_example.png' />
                    </div>

                    <div>
                        <p>Puedes descargar la plantilla del archivo haciendo <a href='/assets/excel-template.xlsx' download>Click aqui</a></p>

                        <ul>
                            <li>nombre: Nombre(s) de la persona.</li>
                            <li>apellidos: Apellido(s) de la persona.</li>
                            <li>tipo: Tipo de registro, este tiene que estar en el sistema.</li>
                            <li>calle: Calle (ubicacion)</li>
                            <li>numero: Numero de vivienda (ubicacion)</li>
                            <li>colonia: Colonia (ubicacion) </li>
                            <li>ciudad: Ciudad (ubicacion)</li>
                            <li>estado: Estado (ubicacion)</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Modal para confirmar actualizacion */}
            {
                showConfirmModal &&
                <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar subida</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Estas a punto de subir varios registros, deseas continuar?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={() => { setShowConfirmModal(false); handleSubmit(); }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {/* Modal de error al actualizar */}
            {!!useCreateRecordExcelErrors && (
                <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>No se ha podido subir los registros</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{useCreateRecordExcelErrors.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowErrorModal(false)}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de completada al actualizar */}
            {useCreateRecordExcelResponse && (
                <Modal show={showSuccessModal} onHide={() => { window.location.reload(); }} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Registros subidos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Registros subidos con exito</p>
                        {(useCreateRecordExcelResponse.total_errors > 0)
                            &&
                            <>
                                <p>Errores totales: {useCreateRecordExcelResponse.total_errors}</p>
                                <p>Errores en las lineas: {useCreateRecordExcelResponse.rows_errors.map((value, idx) =>
                                    (idx < useCreateRecordExcelResponse.rows_errors.length - 1)
                                        ?
                                        `${value}, `
                                        :
                                        `${value}`)}</p>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { window.location.reload(); }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}
