import axios from 'axios';

import { UserState } from '../interfaces/UserState';
import { UserType } from '../interfaces/UserType';

import { verifySession } from './verifySession';

export const isUserAdmin = async () => {
    const userCookie = localStorage.getItem('session');

    if (!userCookie) return false;

    const { userData } = JSON.parse(userCookie) as UserState;

    const session = await verifySession();

    if (!session) return false;

    const { user_type_id } = session;

    const userTypes: UserType[] | false = await axios.get<UserType[]>(`${process.env.REACT_APP_API_URL}/user-types`, { params: { token: userData?.token } })
        .then(({ data }) => data)
        .catch(() => false);

    if (!userTypes) return false;


    return userTypes.find(({ id }) => id === user_type_id)?.name.toLowerCase() === "administrador".toLowerCase();
}
