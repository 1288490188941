import React from 'react'
import { useJsApiLoader, GoogleMap } from '@react-google-maps/api';

import { Spinner } from '../Spinner';

interface Props {
  // Se limitara a los Markers pero se nombra children por la facilidad
  children?: React.ReactNode;
}

const center = {
  lat: 25.5486137,
  lng: -103.4019159
};

const options: google.maps.MapOptions = { zoom: 12, center, mapId: '42032cb6552c66c8' }

export const Map = ({ children }: Props) => {
  const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_MAPS_KEY as string });

  const renderMap = () => {
    return (
      <GoogleMap options={options} mapContainerClassName={"w-100 h-100"}>
        {children}
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Hubo un error al cargar el mapa</div>
  }


  return isLoaded ? renderMap() : <Spinner />
}
