import { useState } from 'react';
import axios, { AxiosError } from 'axios';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';

// Generated by https://quicktype.io

export interface Response {
    rows_errors: number[];
    total_errors: number;
}

export const useCreateRecordsExcel = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);
    const [response, setResponse] = useState<Response>();

    const handleCreate = async (formData: FormData) => {
        setLoading(true);
        setErrors(false);
        setResponse(undefined);

        setTimeout(() => {
            axios.post<Response>(`${process.env.REACT_APP_API_URL}/records/store-excel`, formData,
                { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => setResponse(response.data))
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }, 2000)
    }

    return { loading, errors, response, handleCreate }
}
