import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

import { Spinner } from '../../../components/Spinner';

import { useCreateRecordType } from '../../../hooks/recordTypes/useCreateRecordType';

import { useForm } from '../../../hooks/utils/useForm';
import { REGEX_ONLY_LETTERS_AND_SPACES } from '../../../utils/regex';

interface InitialFormProps {
    name: string,
}

const initialForm: InitialFormProps = {
    name: "",
}

const validateForm = (form: InitialFormProps) => {
    const { name } = form;

    interface ErrorForm {
        name?: string,
    }

    const errors: ErrorForm = {};

    if (!REGEX_ONLY_LETTERS_AND_SPACES.test(name)) {
        errors.name = "Nombre no valido";
    }

    return errors;
}

export const RecordTypesNew = () => {
    const {
        success: useCreateRecordTypeSuccess,
        loading: useCreateRecordTypeLoading,
        errors: useCreateRecordTypeErrors,
        handleCreate
    } = useCreateRecordType();

    const { form, errors: formErrors, handleChange, handleBlur } = useForm(initialForm, validateForm);

    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        if (Object.keys(formErrors).length > 0) {
            setSaveButtonEnabled(false);
        } else {
            setSaveButtonEnabled(true);
        }

        return () => {
            setSaveButtonEnabled(false)
        }
    }, [formErrors]);

    // Titulo del tab
    useEffect(() => {
        document.title = `Nuevo tipo de registro - ${process.env.REACT_APP_TITLE_PAGE}`
    }, []);

    if (useCreateRecordTypeLoading) {
        return <Spinner />
    }

    return (
        <>
            <Form className={`bg24 rounded p-4`} onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }}>
                <h3 className="mb-4 text-center">Creando nuevo registro</h3>

                {/* nombre */}
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Tipo de registro</Form.Label>
                    <Form.Control
                        name="name"
                        type="text"
                        placeholder="Tipo de registro"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                        value={form.name}
                    />

                    {("name" in formErrors) &&
                        <label className="text-danger mt-1">
                            {formErrors.name}
                        </label>
                    }
                </Form.Group>

                <Button
                    variant='success'
                    type='submit'
                    className='w-100'
                    disabled={!saveButtonEnabled}>Crear</Button>
            </Form>

            {/* Modal de confirmacion al crear */}
            {showConfirmModal && (
                <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar creacion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de crear un nuevo tipo de registro, deseas continuar?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={() => { setShowConfirmModal(false); handleCreate(form.name); }}>
                            Crear
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de error al crear */}
            {!!useCreateRecordTypeErrors && (
                <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error al crear</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{useCreateRecordTypeErrors.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowErrorModal(false)}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de completada al crear */}
            {useCreateRecordTypeSuccess && (
                <Modal show={useCreateRecordTypeSuccess} onHide={() => { window.location.reload(); }} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tipo de registro creado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>El tipo de registro se ha creado con exito.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { window.location.reload(); }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}
