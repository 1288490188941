import React, { useState, useEffect } from 'react'
import { Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';

import { Map } from '../../../components/Map';
import { Spinner } from '../../../components/Spinner';

import { useForm } from '../../../hooks/utils/useForm';
import { useRecordTypes } from '../../../hooks/recordTypes/useRecordTypes';
import { useSearchAddress } from '../../../hooks/utils/useSearchAddress';
import { useCreateRecord } from '../../../hooks/record/useCreateRecord';

import { capitalizeFirstLetterOfEachWord } from '../../../utils/capitalizeFirstLetterOfEachWord';
import { REGEX_ONLY_LETTERS_AND_SPACES, REGEX_ONLY_NUMBERS } from '../../../utils/regex';

import pin_image from "../../../assets/pin-mapa.png";

interface InitialFormProps {
    name: string,
    last_names: string,
    record_type_id: number,
    location_formated: string,
}

const initialForm: InitialFormProps = {
    name: "",
    last_names: "",
    record_type_id: 1,
    location_formated: "",
}

const validateForm = (form: InitialFormProps) => {
    const { name, last_names, record_type_id } = form;

    interface ErrorForm {
        name?: string,
        last_names?: string,
        record_type_id?: string
    }

    const errors: ErrorForm = {};

    if (!REGEX_ONLY_LETTERS_AND_SPACES.test(name)) {
        errors.name = "Nombre no valido";
    }

    if (!REGEX_ONLY_LETTERS_AND_SPACES.test(last_names)) {
        errors.last_names = "Apellidos no validos";
    }

    if (!REGEX_ONLY_NUMBERS.test(`${record_type_id}`) || record_type_id < 2) {
        errors.record_type_id = "Tipo de registro invalido";
    }

    return errors;
}

export const RecordNew = () => {
    const navigate = useNavigate();

    const { form, handleBlur, handleChange, errors: formErrors } = useForm(initialForm, validateForm);

    const { recordId, handleCreate, loading: useCreateRecordLoading, success: useCreateRecordSuccess, errors: useCreateRecordErrors } = useCreateRecord();
    const { recordTypes, loading: useRecordTypesLoading, errors: useRecordTypesErrors } = useRecordTypes();
    const { address, loading: useSearchAddressLoading, errors: useSearchAddressErrors, handleAddress } = useSearchAddress();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        if (!address) return;

        handleChange("location_formated", address.formated_address);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    useEffect(() => {
        setShowSuccessModal(useCreateRecordSuccess);
        return () => {
        }

    }, [useCreateRecordSuccess]);

    useEffect(() => {
        setShowErrorModal(!!useCreateRecordErrors);
        return () => {
        }
    }, [useCreateRecordErrors]);

    // Titulo del tab
    useEffect(() => {
        document.title = `Crear registro - ${process.env.REACT_APP_TITLE_PAGE}`
    }, []);

    if (useRecordTypesLoading || useCreateRecordLoading) {
        return <Spinner />
    }

    if (!useRecordTypesLoading && useRecordTypesErrors) {
        return <div>Ha ocurrido un error al cargar los tipos de registro: {useRecordTypesErrors.message}</div>
    }

    const handleSubmit = () => {
        if (!address) return;

        handleCreate(form.name, form.last_names, form.record_type_id, address.formated_address, address.latitude, address.longitude);
    }

    return (
        <>
            <Form className={`bg24 rounded p-4`} onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }}>
                <h3 className="mb-4 text-center">Creando nuevo registro</h3>

                {/* nombre */}
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Nombre(s)</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Nombre(s)" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.name} />
                    {("name" in formErrors) &&
                        <label className="text-danger mt-1">
                            {formErrors.name}
                        </label>
                    }
                </Form.Group>

                {/* apellidos */}
                <Form.Group className="mb-3" controlId="formBasicLastNames">
                    <Form.Label>Apellido(s)</Form.Label>
                    <Form.Control name="last_names" type="text" placeholder="Apellido(s)" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.last_names} />
                    {("last_names" in formErrors) &&
                        <label className="text-danger mt-1">
                            {formErrors.last_names}
                        </label>
                    }
                </Form.Group>

                {/* Tipos de registro */}
                <Form.Group className="mb-3" controlId="formBasicRecordType">
                    <Form.Label>Tipo de registro</Form.Label>
                    <Form.Select aria-label="Seleccionar el tipo de registro" name='record_type_id' value={form.record_type_id} onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)}>
                        {recordTypes
                            .sort((a, b) => a.id > b.id ? 1 : -1)
                            .map((recordType) => {
                                return <option key={`selectRecordType${recordType.id}`} value={recordType.id}>{capitalizeFirstLetterOfEachWord(recordType.name)}</option>
                            })}
                    </Form.Select>
                    {("record_type_id" in formErrors) &&
                        <label className="text-danger mt-1">
                            {formErrors.record_type_id}
                        </label>
                    }
                </Form.Group>

                {/* Direccion */}
                <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Direccion</Form.Label>

                    <div className='d-flex'>
                        <div className='col-md-10'>
                            <Form.Control name="location_formated" type="text" placeholder="Direccion" onChange={(e) => handleChange(e.target.name, e.target.value)} value={form.location_formated} disabled={useSearchAddressLoading} />
                        </div>
                        <div className='col-md-2'>
                            <Button className='w-100' variant='primary' type='button' onClick={() => { handleAddress(form.location_formated) }} disabled={useSearchAddressLoading}>Buscar</Button>
                        </div>
                    </div>
                </Form.Group>

                {useSearchAddressLoading
                    ?
                    <label>Buscando...</label>
                    :
                    <label>Direccion: {address ? address.formated_address : "Ninguna direccion"}</label>
                }

                {(useSearchAddressErrors) &&
                    <p className="text-danger mt-1 m-0 p-0">
                        Error al buscar la ubicacion: {useSearchAddressErrors.message}
                    </p>
                }

                <div className='w-100 mb-4 mt-4' style={{ height: '700px' }}>
                    <Map>
                        {address &&
                            <Marker key={`markerNew`} position={{ lat: address.latitude, lng: address.longitude }} icon={pin_image} />
                        }
                    </Map>
                </div>

                <Button variant='success' type='submit' className='w-100' disabled={Object.keys(formErrors).length > 0 || !address || useSearchAddressLoading}>Guardar</Button>
            </Form>

            {/* Modal para confirmar creacion */}
            {
                showConfirmModal &&
                <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar creacion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Estas a punto de crear un nuevo registro, deseas continuar?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={() => { setShowConfirmModal(false); handleSubmit(); }}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {/* Modal de error al actualizar */}
            {!!useCreateRecordErrors && (
                <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>No se ha podido crear el registro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{useCreateRecordErrors.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowErrorModal(false)}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de completada al actualizar */}
            {(useCreateRecordSuccess && recordId) && (
                <Modal show={showSuccessModal} onHide={() => { navigate(`/records/${recordId}`) }} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>El registro se ha creado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Registro creado con exito, seras redireccionado al registro.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { navigate(`/records/${recordId}`) }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}