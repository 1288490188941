import { useState } from 'react'
import { useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { RootState } from '../../store/store';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';

export const useCreateRecord = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [recordId, setRecordId] = useState<number>();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);
    const [success, setSuccess] = useState(false);

    const handleCreate = (name: string, last_names: string, record_type_id: number, location_formated: string, location_lat: number, location_lng: number) => {
        setLoading(true);
        setErrors(false);
        setSuccess(false);

        setTimeout(async () => {
            await axios.post<number>(`${process.env.REACT_APP_API_URL}/records`, {
                name,
                last_names,
                record_type_id,
                location_formated,
                location_lat,
                location_lng,
                token: userData?.token
            })
                .then((response) => {
                    setRecordId(response.data);
                    setErrors(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse);
                        }
                    }

                    setSuccess(false);
                })
                .finally(() => setLoading(false));
        }, 2000);
    }

    return { recordId, loading, errors, success, handleCreate }
}
