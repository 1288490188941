import React, { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import { isUserAdmin } from '../utils/isUserAdmin';

export const AdminRoutes = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        const handleIsAdmin = async () => {
            const isAdmin = await isUserAdmin();

            if (!isAdmin) {
                navigate('/', { replace: true });
            }
        }

        handleIsAdmin();

        return () => {
        }
    }, [navigate, pathname])


    return <Outlet />;
}
