import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import { Spinner } from '../../../components/Spinner';
import { RecordType } from './components/RecordType';

import { useRecordTypes } from '../../../hooks/recordTypes/useRecordTypes'

export const RecordTypesSettings = () => {
    const { recordTypes, loading: useRecordTypesLoading, errors: useRecordTypesErrors } = useRecordTypes();

    // Titulo del tab
    useEffect(() => {
        document.title = `Configuracion de tipo de registros - ${process.env.REACT_APP_TITLE_PAGE}`
    }, []);


    if (useRecordTypesLoading) {
        return <Spinner />
    }

    if (useRecordTypesErrors) {
        return <div>Ha ocurrido un error al cargar los tipos de registro: {useRecordTypesErrors.message}</div>
    }

    return (
        <div>
            <h3 className="mb-4 text-center">Tipos de registro</h3>
            <div className='d-flex justify-content-end mb-2'>
                <Link className='btn btn-primary' to={'new'}>Crear nuevo</Link>
            </div>
            {recordTypes.length > 0
                ?
                recordTypes.map((recordType, idx) => {
                    return (recordType.id !== 1) && <RecordType key={`recordType${recordType.id}`} recordType={recordType} idList={idx + 1} />
                })
                :
                <div>No hay tipos de registro</div>
            }
        </div>
    )
}
