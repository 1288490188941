import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';
import { RecordType } from '../../interfaces/RecordType';

import { RootState } from '../../store/store';

export const useRecordTypes = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [recordTypes, setRecordTypes] = useState<RecordType[]>([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);

    useEffect(() => {
        setLoading(true);

        const handleRecordTypes = async () => {
            setTimeout(async () => {
                await axios.get<RecordType[]>(`${process.env.REACT_APP_API_URL}/record-types`, { params: { token: userData?.token } })
                    .then((response) => {
                        setRecordTypes(response.data);
                        setErrors(false);
                    })
                    .catch((error) => {
                        if (axios.isAxiosError(error)) {
                            const err = error as AxiosError;

                            if (!err.response) {
                                setErrors({ message: "Error al tratar de contactar con el servidor" });
                            } else {
                                setErrors(err.response.data as ErrorApiResponse);
                            }
                        }
                    })
                    .finally(() => setLoading(false));
            }, 2000);
        }

        handleRecordTypes();
        return () => {
            setRecordTypes([]);
            setLoading(false);
            setErrors(false);
        }
    }, [userData]);


    return { recordTypes, loading, errors }
}
