import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import { RootState } from "../../store/store";

import { logout as storeLogout } from "../../store/userSlice"

export const useLogout = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const logout = () => {
        setLoading(true);

        setTimeout(async () => {
            await axios.delete(`${process.env.REACT_APP_API_URL}/users/logout`, { params: { token: userData?.token } })
                .then(() => { })
                .catch(() => { })
                .finally(() => {
                    dispatch(storeLogout());
                    setLoading(false);
                });
        }, 2000)

    }

    return { loading, logout }
}
