import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";

import { RootLayout } from "./RootLayout";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { UnloggedRoutes } from "./UnloggedRoutes";

import { Dashboard } from "../pages/protected/Dashboard";
import { loader as recordLoader, Record } from "../pages/protected/Record";
import { loader as recordEditLoader, RecordEdit } from "../pages/protected/RecordEdit";
import { RecordNew } from "../pages/protected/RecordNew";
import { RecordExcel } from "../pages/protected/RecordExcel";

import { Register } from "../pages/unlogged/Register";
import { Login } from "../pages/unlogged/Login";

import { AdminRoutes } from "./AdminRoutes";
import { RecordTypesSettings } from '../pages/admin/RecordTypesSettings/index';
import { RecordTypesNew } from "../pages/admin/RecordTypesNew";

export const Router = () => {
  return <RouterProvider router={router} />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      {/* Protected Routes */}
      <Route element={<ProtectedRoutes />}>
        <Route element={<Dashboard />} index />

        {/* Records Routes  */}
        <Route path="/records">
          <Route element={<Record />} path=":id" loader={recordLoader} errorElement={<Navigate to={"/"} replace />} />
          <Route element={<RecordEdit />} path=":id/edit" loader={recordEditLoader} errorElement={<Navigate to={"/"} replace />} />
          <Route element={<RecordNew />} path="new" />
          <Route element={<RecordExcel />} path="new/excel" />
        </Route>

        {/* Admin Routes */}
        <Route element={<AdminRoutes />} path={"/settings"}>
          <Route path={"record-types"}>
            <Route element={<RecordTypesSettings />} index />
            <Route element={<RecordTypesNew />} path={"new"} />
          </Route>
        </Route>

        <Route element={<Navigate to="/" replace />} path={'*'} />
      </Route>

      {/* Unlogged Routes */}
      <Route element={<UnloggedRoutes />}>
        <Route element={<Register />} path={"/register"} />
        <Route element={<Login />} path={"/login"} />

        <Route element={<Navigate to="/login" replace />} path={'*'} />
      </Route>
    </Route>
  )
);
