import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

export const UnloggedRoutes = () => {
  const {
    user: { logged },
  } = useSelector((state: RootState) => state);

  return !logged ? <Outlet /> : <Navigate to={"/"} />;
};
