import { Record } from "../interfaces/Record";
import { RecordType } from '../interfaces/RecordType';

export const filterRecordsByTextSearch = (records: Record[], match: string, recordTypes: RecordType[]): Record[] => {

    const filterFullname = records.filter(({ name, last_names }) => {
        const fullName = `${name} ${last_names}`.toUpperCase();

        return fullName
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .match(match.toUpperCase())
    });

    if (filterFullname.length > 0) {
        return filterFullname;
    }

    const filterAddress = records.filter(({ location_formated }) =>
        location_formated.toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .match(match.toUpperCase())
    );

    if (filterAddress.length > 0) {
        return filterAddress;
    }

    const findType = recordTypes.find(({ name }) =>
        name
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .match(match.toUpperCase())
    );

    const filterType = records.filter(
        ({ record_type_id }) => findType?.id === record_type_id
    );

    if (filterType.length > 0) {
        return filterType;
    }

    return [];
};