import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';
import { Address } from '../../interfaces/Address';

export const useSearchAddress = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [address, setAddress] = useState<Address>();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);


    const handleAddress = async (formatedAddress: string) => {
        setLoading(true);
        setTimeout(async () => {
            await axios.post<Address>(`${process.env.REACT_APP_API_URL}/google-maps-api/search-address`, { formatedAddress, token: userData?.token })
                .then((response) => {
                    setAddress(response.data);
                    setErrors(false);
                })
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }, 2000);
    }


    return { address, loading, errors, handleAddress }
}
