import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Sidebar } from "../components/Sidebar";

import { RootState } from "../store/store";
import { logout } from '../store/userSlice';

import { verifySession } from '../utils/verifySession';

import styles from "../styles/components/_layout.module.scss";

export const ProtectedRoutes = () => {
  const {
    user: { logged },
  } = useSelector((state: RootState) => state);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  // useEffect para verificar si la sesion del usuario es valida
  useEffect(() => {
    const handleSession = async () => {
      const isValidSession = await verifySession();

      if (!isValidSession) {
        dispatch(logout());
      }
    }

    handleSession();

    return () => {
    }
  }, [dispatch, pathname])



  return logged ?
    <div className={`${styles.layout} pe-2`}>
      <div className={`${styles.sidebar} bg24 position-fixed`}>
        <Sidebar />
      </div>

      <div className={`${styles.outlet} mb-5 mt-4`}>
        <Outlet />
      </div>
    </div>
    :
    <Navigate to={"/login"} />;
};
