import React from 'react'
import { Link } from 'react-router-dom';

import { useRecordTypes } from '../../hooks/recordTypes/useRecordTypes'

import { Record } from '../../interfaces/Record'

import { Spinner } from '../Spinner';

import { capitalizeFirstLetterOfEachWord } from '../../utils/capitalizeFirstLetterOfEachWord';

import styles from "./styles/main.module.scss";

interface Props {
    records: Record[]
    totalRecords: number
}

export const RecordList = ({ records, totalRecords }: Props) => {

    const { recordTypes, loading: useRecordTypesLoading } = useRecordTypes();


    if (useRecordTypesLoading) {
        return <Spinner />
    }

    return (
        <div className={`${styles.list}`}>
            <h4 className='mb-3'>Registros encontrados {records.length} de {totalRecords}</h4>
            {records.map(({ id, name, last_names, location_formated, record_type_id }, idx) => {
                let loading = true;

                const recordType = recordTypes.find((recordType, idx) => {
                    if (recordType.id === record_type_id) {
                        loading = false;
                        return recordType;
                    }

                    if (idx === recordTypes.length - 1) {
                        loading = false;
                    }

                    return false;
                });

                const fullNameCapitalized = capitalizeFirstLetterOfEachWord(`${name} ${last_names}`);
                const locationCapitalized = capitalizeFirstLetterOfEachWord(location_formated);
                const recordTypeCapitalized = capitalizeFirstLetterOfEachWord(recordType?.name ? recordType.name : "No se pudo recuperar.");

                if (loading) {
                    return <Spinner key={`record${id}`} />
                }

                return (
                    <Link key={`record${id}`} to={`/records/${id}`} className='text-decoration-none '>
                        <div className={`${styles.record} mb-3 p-4`}>
                            <div className='col-8 me-1'>
                                <p className={`${styles.text}`}>Nombre: {fullNameCapitalized}</p>
                                <p className={`${styles.text}`}>Direccion: {locationCapitalized}</p>
                                <p className={`${styles.text}`}>Tipo: {recordTypeCapitalized}</p>
                            </div>

                            <div className='col-4 d-flex flex-row-reverse'>
                                <p className={`${styles.text}`}>Registro {idx + 1}</p>
                            </div>
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}
