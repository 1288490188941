import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';
import { Record } from '../../interfaces/Record';

import { RootState } from '../../store/store';

export const useUpdateRecord = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);
    const [success, setSuccess] = useState(false);

    const handleUpdate = ({ id, name, last_names, record_type_id, location_formated, location_lat, location_lng }: Record) => {
        setLoading(true);
        setErrors(false);
        setSuccess(false);

        setTimeout(async () => {
            await axios.put(`${process.env.REACT_APP_API_URL}/records/${id}`, {
                name,
                last_names,
                record_type_id,
                location_formated,
                location_lat,
                location_lng,
                token: userData?.token
            })
                .then(() => {
                    setErrors(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse);
                        }
                    }

                    setSuccess(false)
                })
                .finally(() => setLoading(false));
        }, 2000)
    }

    return { loading, errors, success, handleUpdate }
}
