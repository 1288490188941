import React, { useEffect, useState } from 'react'
import { Link, LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Marker } from '@react-google-maps/api';

import { Spinner } from '../../../components/Spinner';
import { Map } from '../../../components/Map';

import { useRecord } from '../../../hooks/record/useRecord';
import { useRecordType } from '../../../hooks/recordTypes/useRecordType';

import { Record as RecordInterface } from '../../../interfaces/Record';

import { capitalizeFirstLetterOfEachWord } from '../../../utils/capitalizeFirstLetterOfEachWord';
import { useDeleteRecord } from '../../../hooks/record/useDeleteRecord';

import pin_image from "../../../assets/pin-mapa.png";

interface LoaderDataProps {
    id: number
}

export const Record = () => {
    const { id: idParam } = useLoaderData() as LoaderDataProps;
    const navigate = useNavigate();
    const { record, loading: useRecordLoading, errors: useRecordErrors } = useRecord(idParam);
    const { recordType, loading: useRecordTypeLoading, errors: useRecordTypeErrors } = useRecordType(record?.record_type_id as number);
    const { success: useDeleteRecordSuccess, loading: useDeleteRecordLoading, errors: useDeleteRecordErrors, handleDelete } = useDeleteRecord();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        setShowSuccessModal(useDeleteRecordSuccess);
        return () => {
        }
    }, [useDeleteRecordSuccess]);

    useEffect(() => {
        setShowErrorModal(!!useDeleteRecordErrors);
        return () => {
        }
    }, [useDeleteRecordErrors]);

    // Titulo del tab
    useEffect(() => {
        if (!record) { document.title = `Cargando...`; return; }

        document.title = `Registro de ${capitalizeFirstLetterOfEachWord(`${record.name} ${record.last_names}`)} - ${process.env.REACT_APP_TITLE_PAGE}`

    }, [record]);

    if (useRecordLoading || useRecordTypeLoading || useDeleteRecordLoading) {
        return <Spinner />
    }

    if (!!useRecordErrors && !record && !useRecordLoading) {
        return <div>Ha ocurrido un error al recuperar el registro: {useRecordErrors.message}</div>
    }

    let { id, name, last_names, location_formated, location_lat, location_lng } = record as RecordInterface;

    const fullName = capitalizeFirstLetterOfEachWord(`${name} ${last_names}`);
    location_formated = capitalizeFirstLetterOfEachWord(`${location_formated}`);

    return (
        <div>
            <div>
                <h2>{fullName}</h2>
            </div>
            <ul className='mb-3'>
                <li>Direccion: {location_formated}</li>
                <li>Tipo de registro: {recordType
                    ? capitalizeFirstLetterOfEachWord(recordType.name)
                    :
                    (useRecordTypeErrors)
                        ? useRecordTypeErrors.message
                        : "No se ha podido recuperar el tipo"}</li>
            </ul>

            <div className='w-100 mb-4' style={{ height: '700px' }}>
                <Map>
                    <Marker key={`marker${id}`} position={{ lat: location_lat, lng: location_lng }} icon={pin_image} />
                </Map>
            </div>

            <div className='d-grid gap-3'>
                <Link to={`/records/${id}/edit`} className='btn btn-info text-white'>Editar</Link>
                <Button variant='danger' onClick={() => setShowConfirmDeleteModal(true)}>Eliminar</Button>
            </div>

            {/* Modal para confirmar actualizacion */}
            {
                showConfirmDeleteModal &&
                <Modal show={showConfirmDeleteModal} onHide={() => setShowConfirmDeleteModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar actualizacion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Estas a punto de actualizar este registro al confirmar todos estos datos cambiaran y no se podra deshacer.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmDeleteModal(false)}>
                            Cancelar
                        </Button>

                        {record &&
                            <Button variant="danger" onClick={() => { setShowConfirmDeleteModal(false); handleDelete(record.id); }}>
                                Eliminar
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            }

            {/* Modal de error al actualizar */}
            {!!useDeleteRecordErrors && (
                <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>No se ha podido actualizar el registro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{useDeleteRecordErrors.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowErrorModal(false)}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de completada al actualizar */}
            {useDeleteRecordSuccess && (
                <Modal show={showSuccessModal} onHide={() => { navigate('/', { replace: true }) }} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>El registro se ha eliminado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Registro eliminado con exito, redireccionando.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { navigate('/', { replace: true }) }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

export const loader = ({ params }: LoaderFunctionArgs) => {
    if (Number.isNaN(parseInt(params.id as string))) {
        throw new Error("No es un id valido");
    }

    return { id: params.id };
}
