import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

import { Spinner } from '../../../../../components/Spinner'

import { useForm } from '../../../../../hooks/utils/useForm'
import { useDeleteRecordType } from '../../../../../hooks/recordTypes/useDeleteRecordType';
import { useUpdateRecordType } from '../../../../../hooks/recordTypes/useUpdateRecordType'

import { RecordType as RecordTypeInterface } from '../../../../../interfaces/RecordType'

import { REGEX_ONLY_LETTERS_AND_SPACES } from '../../../../../utils/regex'
import { capitalizeFirstLetterOfEachWord } from '../../../../../utils/capitalizeFirstLetterOfEachWord';

interface Props {
    recordType: RecordTypeInterface
    idList: number
}

interface InitialFormProps {
    name: string
}

const validateForm = (form: InitialFormProps) => {
    const { name } = form;

    interface ErrorForm {
        name?: string,
    }

    const errors: ErrorForm = {};

    if (!REGEX_ONLY_LETTERS_AND_SPACES.test(name)) {
        errors.name = "Nombre no valido";
    }

    return errors;
}

export const RecordType = ({ recordType: { id, name }, idList }: Props) => {
    const initialForm: InitialFormProps = { name }

    const { form, handleBlur, handleChange, errors: formErrors } = useForm(initialForm, validateForm);

    const { success: useDeleteRecordTypeSuccess, loading: useDeleteRecordTypeLoading, errors: useDeleteRecordTypeErrors, handleDelete } = useDeleteRecordType();
    const { success: useUpdateRecordTypeSuccess, loading: useUpdateRecordTypeLoading, errors: useUpdateRecordTypeErrors, handleUpdate } = useUpdateRecordType();

    const [editable, setEditable] = useState(false);

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);

    const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
    const [showUpdateErrorModal, setShowUpdateErrorModal] = useState(false);

    useEffect(() => {

        if (Object.keys(formErrors).length > 0) {
            setEditable(false);
        } else {
            setEditable(true);
        }

        return () => {
            setEditable(false);
        }
    }, [formErrors]);


    if (useDeleteRecordTypeLoading || useUpdateRecordTypeLoading) {
        return <Spinner />
    }


    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId={`formBasicRecordType${id}`}>
                    {/* nombre */}
                    <Form.Group className="mb-3 d-flex" controlId="formBasicName">
                        <div className='col-8 me-1'>
                            <Form.Label>Tipo de registro {idList}</Form.Label>
                            <Form.Control name="name"
                                type="text"
                                placeholder="Tipo de registro"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                                value={capitalizeFirstLetterOfEachWord(form.name)} />

                            {("name" in formErrors) &&
                                <label className="text-danger mt-1">
                                    {formErrors.name}
                                </label>
                            }
                        </div>

                        <div className='col-4 d-flex align-items-end'>
                            <Button type='button' variant='danger' className='me-1' onClick={() => setShowDeleteConfirmModal(true)}>Eliminar</Button>
                            <Button type='button' variant='success' disabled={!editable} onClick={() => setShowUpdateConfirmModal(true)}>Guardar</Button>
                        </div>
                    </Form.Group>
                </Form.Group>
            </Form>

            {/* Modal de confirmacion al eliminar */}
            {showDeleteConfirmModal && (
                <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar eliminacion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de eliminar el tipo de registro {capitalizeFirstLetterOfEachWord(name)}, deseas continuar?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={() => { setShowDeleteConfirmModal(false); handleDelete(id); }}>
                            Actualizar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de error al eliminar */}
            {!!useDeleteRecordTypeErrors && (
                <Modal show={showDeleteErrorModal} onHide={() => setShowDeleteErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error al eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{useDeleteRecordTypeErrors.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowDeleteErrorModal(false)}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de completada al eliminar */}
            {useDeleteRecordTypeSuccess && (
                <Modal show={useDeleteRecordTypeSuccess} onHide={() => { window.location.reload(); }} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tipo de registro eliminado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>El tipo de registro se ha eliminado con exito.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { window.location.reload(); }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de confirmacion al actualizar */}
            {showUpdateConfirmModal && (
                <Modal show={showUpdateConfirmModal} onHide={() => setShowUpdateConfirmModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar actualizacion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estas a punto de actualizar el tipo de registro {capitalizeFirstLetterOfEachWord(name)}, deseas continuar?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowUpdateConfirmModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={() => { setShowUpdateConfirmModal(false); handleUpdate(id, form.name); }}>
                            Actualizar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de error al actualizar */}
            {!!useUpdateRecordTypeErrors && (
                <Modal show={showUpdateErrorModal} onHide={() => setShowUpdateErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error al actualizar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{useUpdateRecordTypeErrors.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowUpdateErrorModal(false)}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Modal de completada al actualizar */}
            {useUpdateRecordTypeSuccess && (
                <Modal show={useUpdateRecordTypeSuccess} onHide={() => { window.location.reload(); }} centered={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tipo de registro actualizado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>El tipo de registro se ha actualizado con exito.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { window.location.reload(); }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}
