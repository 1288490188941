import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Form } from "react-bootstrap";

import { useForm } from '../../hooks/utils/useForm';

interface Props {
    placeholder?: string
}

const initialForm = {
    search: ""
}

export const Searchbar = ({ placeholder }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { form, handleChange, handleBlur } = useForm(initialForm);

    useEffect(() => {

        if (form.search.length === 0) {
            searchParams.delete('search');
        }

        if (form.search.length > 0) {
            searchParams.set('search', form.search);
        }

        setSearchParams(searchParams);

        return () => {
        }
    }, [form.search, searchParams, setSearchParams])


    return (
        <Form.Group className='mb-1'>
            <Form.Control name='search' type='text' placeholder={placeholder} onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.search} />
        </Form.Group>
    )
}
