export const capitalizeFirstLetterOfEachWord = (sentence: string) => {
    const words = sentence.toLowerCase().split(" ");

    words.forEach((word, idx) => {
        try {
            words[idx] = word[0].toUpperCase() + word.substring(1);
        } catch {

        }
    });

    return words.join(" ");
}