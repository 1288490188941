import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Collapse, Modal } from 'react-bootstrap';

import { FaHome } from "react-icons/fa";
import { BsFillGearFill, BsFillPersonFill, BsFillPersonPlusFill } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { AiFillProfile, AiOutlineLogout } from "react-icons/ai";

import { useLogout } from '../../hooks/user/useLogout';

import { Spinner } from '../Spinner';

import styles from "./styles/main.module.scss";
import { isUserAdmin } from '../../utils/isUserAdmin';

export const Sidebar = () => {
    const { logout, loading: useLogoutLoading } = useLogout();

    const [collapseRecords, setCollapseRecords] = useState(false);
    const [collapseSettings, setCollapseSettings] = useState(false);

    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);

    // Verificando si es usuario administrador
    useEffect(() => {
        const handleIsAdmin = async () => {
            setIsAdmin(await isUserAdmin());
        }

        handleIsAdmin();

        return () => {
        }
    }, [])


    return (
        <>
            <div className={`d-flex flex-column text-white min-vh-100 ${styles.sidebar} justify-content-between`}>
                <div className='pt-4'>
                    {/* Logo */}
                    <Link to={'/'} className="d-flex align-items-center pb-3 text-white text-decoration-none px-md-3">
                        <span className="fs-3 d-none d-md-inline">Mapas</span>
                    </Link>

                    <ul className="nav nav-pills flex-column align-items-center align-items-md-start px-md-3 mb-0 w-100">
                        {/* Inicio */}
                        <li className="nav-item">
                            <Link to={'/'}>
                                <FaHome /><span className="ms-1 d-none d-md-inline">Inicio</span>
                            </Link>
                        </li>

                        {/* Registros */}
                        <li className='nav-item'>
                            <Button className="nav-link px-0 align-middle" onClick={() => setCollapseRecords(!collapseRecords)}>
                                <BsFillPersonFill /><span className="ms-1 d-none d-md-inline">Registros</span>
                            </Button>

                            <Collapse in={collapseRecords}>
                                <ul className="nav flex-column ms-1">
                                    <li className="w-100">
                                        <Link to={'/records/new'}>
                                            <BsFillPersonPlusFill /><span className="ms-1 d-none d-md-inline">Agregar registro</span>
                                        </Link>
                                    </li>
                                    <li className="w-100">
                                        <Link to={'/records/new/excel'}>
                                            <RiFileExcel2Fill /><span className="ms-1 d-none d-md-inline">Agregar registros via excel</span>
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>

                        {/* Configuracion */}
                        {isAdmin &&
                            <li className='nav-item'>
                                <Button className="nav-link px-0 align-middle" onClick={() => setCollapseSettings(!collapseSettings)}>
                                    <BsFillGearFill /><span className="ms-1 d-none d-md-inline">Configuracion</span>
                                </Button>

                                <Collapse in={collapseSettings}>
                                    <ul className="nav flex-column ms-1">
                                        <li className="w-100">
                                            <Link to={'/settings/record-types'}>
                                                <AiFillProfile /><span className="ms-1 d-none d-md-inline">Tipos de registro</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </Collapse>
                            </li>
                        }
                    </ul>
                </div>

                <ul className="nav nav-pills flex-column align-items-center align-items-md-start px-md-3 mb-4 w-100">
                    {/* Logout */}
                    <li className="nav-item">
                        <Button className="nav-link px-0 align-middle" onClick={() => setShowLogoutModal(true)}>
                            <AiOutlineLogout className='text-danger' /><span className="ms-1 d-none d-md-inline">Cerrar sesion</span>
                        </Button>
                    </li>
                </ul>
            </div>

            {/* Modal para el cierre de sesion */}
            <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} centered={true} backdrop="static" keyboard={false}>
                <Modal.Header closeButton={!useLogoutLoading}>
                    <Modal.Title>{!useLogoutLoading ? "Confirmar cierre de sesion" : "Cerrando sesion..."}</Modal.Title>
                </Modal.Header>
                {useLogoutLoading
                    ?
                    <div style={{ minHeight: '150px' }} className='d-flex align-items-center'>
                        <Spinner />
                    </div>
                    :
                    <>
                        <Modal.Body>
                            Estas a punto de cerrar sesion, deseas continuar?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
                                Cancelar
                            </Button>
                            <Button variant="danger" onClick={() => logout()}>
                                Cerrar sesion
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        </>
    )
}
