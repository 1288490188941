import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { RootState } from '../../store/store';

import { Record } from '../../interfaces/Record';
import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';
import { RecordType } from '../../interfaces/RecordType';

import { filterRecordsByTextSearch } from '../../utils/filterRecordByMatch';

export const useRecords = () => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<Record[]>([]);
    const [recordsQuery, setRecordsQuery] = useState<Record[]>([]);
    const [recordsInsidePolygon, setRecordsInsidePolygon] = useState<Record[]>([]);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);

    const handleQuery = useCallback(
        (match: string, recordTypes: RecordType[]) => {
            setRecordsQuery(filterRecordsByTextSearch(records, match, recordTypes));
        },
        [records],
    );

    const handleInsidePoly = useCallback(
        (polygon: google.maps.Polygon) => {
            setRecordsInsidePolygon([]);
            records.forEach((record) => {
                const isInside = google.maps.geometry.poly
                    .containsLocation(new google.maps.LatLng({ lat: record.location_lat, lng: record.location_lng }), polygon);

                if (isInside) {
                    setRecordsInsidePolygon((old) => [...old, record]);
                }
            });
        },
        [records],
    );

    useEffect(() => {
        setLoading(true);
        const handleRecords = () => {
            setTimeout(async () => {
                await axios.get<Record[]>(`${process.env.REACT_APP_API_URL}/records/all`, { params: { token: userData?.token } })
                    .then((response) => {
                        setRecords(response.data);
                        setRecordsQuery(response.data);
                        setErrors(false);
                    })
                    .catch((error) => {
                        if (axios.isAxiosError(error)) {
                            const err = error as AxiosError;

                            if (!err.response) {
                                setErrors({ message: "Error al tratar de contactar con el servidor" });
                            } else {
                                setErrors(err.response.data as ErrorApiResponse);
                            }
                        }
                    })
                    .finally(() => setLoading(false));
            }, 2000);
        }

        handleRecords();

        return () => {
            setRecords([]);
            setRecordsQuery([]);
            setLoading(false);
            setErrors(false);
        }
    }, [userData?.token]);

    return { loading, records, recordsQuery, recordsInsidePolygon, errors, handleQuery, handleInsidePoly }
}
