import { useState } from "react"
import axios, { AxiosError } from 'axios';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';


export const useRegister = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const register = async (email: string, password: string) => {
        setLoading(true);
        setErrors(false);
        setSuccess(false);

        setTimeout(async () => {
            await axios.post(`${process.env.REACT_APP_API_URL}/users`, { email, password })
                .then(() => {
                    setSuccess(true);
                    setErrors(false);
                })
                .catch((error) => {
                    setSuccess(false);
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse)
                        }
                    }
                })
                .finally(() => setLoading(false))
        }, 2000)
    }
    return { success, loading, errors, register }
}
