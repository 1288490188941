import axios from 'axios';

import { User } from '../interfaces/User';
import { UserState } from '../interfaces/UserState';


export const verifySession = async () => {
    const userCookie = localStorage.getItem('session');

    if (!userCookie) return false;

    const { userData } = JSON.parse(userCookie) as UserState;

    const isValidSession: User | false = await axios.get<User>(`${process.env.REACT_APP_API_URL}/users/verify-session`, { params: { token: userData?.token } })
        .then(({ data }) => data)
        .catch(() => false
        );

    return isValidSession;
}
