import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useForm } from "../../../hooks/utils/useForm";
import { useRegister } from "../../../hooks/user/useRegister";

import { REGEX_EMAIL, REGEX_PASSWORD } from "../../../utils/regex";

import { Spinner } from "../../../components/Spinner";

import styles from "./styles/main.module.scss";



interface InitialFormProps {
  email: string,
  password: string,
  passwordConfirm: string
}


const initialForm: InitialFormProps = {
  email: "",
  password: "",
  passwordConfirm: ""
}

const validateForm = (form: InitialFormProps) => {

  const { email, password, passwordConfirm } = form;

  interface ErrorForm {
    email?: string,
    password?: string,
    passwordConfirm?: string
  }

  const errors: ErrorForm = {}

  if (!REGEX_EMAIL.test(email)) {
    errors.email = "Correo electronico no valido"
  }

  if (!REGEX_PASSWORD.test(password)) {
    errors.password = "La contraseña debe contener un numero, una letra y al menos 8 caracteres"
  } else if (password !== passwordConfirm) {
    errors.password = "La confirmacion de la contraseña no coincide"
  }

  return errors;
}



export const Register = () => {
  const { form, handleBlur, handleChange, errors: formErrors, cleanForm } = useForm(initialForm, validateForm);
  const { success: useRegisterSuccess, loading: useRegisterLoading, errors: useRegisterErrors, register } = useRegister();
  const [enableRegisterButton, setEnableRegisterButton] = useState<boolean>(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    if (Object.keys(formErrors).length > 0) return;

    await register(form.email, form.password);
  }

  // useEffect para comprobar si no hay errores en el formulario y activar el boton de registro
  useEffect(() => {
    if (Object.keys(validateForm(form)).length > 0) {
      setEnableRegisterButton(false);
    } else {
      setEnableRegisterButton(true);
    }

    return () => {

    }
  }, [form])


  // useEffect para mostrar el modal con los errores al momento del registro de useRegister
  useEffect(() => {
    setShowErrorModal(!!useRegisterErrors);

    return () => {
      setShowErrorModal(false)
    }
  }, [useRegisterErrors]);

  // useEffect para mostrar el modal de exito al momento del registro de useRegister
  useEffect(() => {
    setShowSuccessModal(useRegisterSuccess);

    return () => {
      setShowSuccessModal(false);
    }
  }, [useRegisterSuccess]);


  // useEffect para limpiar el formulario en caso de exito al momento del registro de useRegister
  useEffect(() => {
    // Si el registro fue exitoso y el modal se esta mostrando se limpiara el formulario
    if (useRegisterSuccess && showSuccessModal) {
      cleanForm();
    }

    return () => {

    }
  }, [cleanForm, showSuccessModal, useRegisterSuccess]);

  // Titulo del tab
  useEffect(() => {
    document.title = `Registro - ${process.env.REACT_APP_TITLE_PAGE}`
  }, []);

  if (useRegisterLoading) {
    return <Spinner />
  }

  return (
    <div className="d-flex justify-content-center">
      <div className={`${styles.form_container}`}>
        <Form className={`bg24 rounded mt-5 p-5`} onSubmit={handleRegister}>
          <h3 className="mb-4 text-center">Registro para Mapas</h3>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Correo Electronico</Form.Label>
            <Form.Control name="email" type="email" placeholder="example@example.com" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.email} />
            {("email" in formErrors) &&
              <label className="text-danger mt-1">
                {formErrors.email}
              </label>
            }
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control name="password" type="password" placeholder="Contraseña" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.password} />
            {("password" in formErrors) &&
              <label className="text-danger mt-1">
                {formErrors.password}
              </label>
            }
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
            <Form.Label>Confirmar contraseña</Form.Label>
            <Form.Control name="passwordConfirm" type="password" placeholder="Confirmar contraseña" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.passwordConfirm} />

          </Form.Group>

          <FormGroup className="mb-3 text-center">
            <p className="text-muted mb-0">
              Ya tienes una cuenta?
            </p>

            <Link to={"/login"} className="text-center">Iniciar sesion!</Link>
          </FormGroup>
          <Button variant="danger" type="submit" className="w-100" disabled={!(!useRegisterLoading && enableRegisterButton)}>
            Registrarse
          </Button>
        </Form>

        {useRegisterSuccess &&
          <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered={true} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Registro estatus</Modal.Title>
            </Modal.Header>
            <Modal.Body>El registro se ha completado con exito</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
                Aceptar
              </Button>
            </Modal.Footer>
          </Modal>
        }

        {!!useRegisterErrors && (
          <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered={true} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>No se ha podido completar el registro</Modal.Title>
            </Modal.Header>
            <Modal.Body>{useRegisterErrors.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowErrorModal(false)}>
                Aceptar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div >
  );
};
