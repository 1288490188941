import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";

import { Record } from "../../interfaces/Record";
import { ErrorApiResponse } from "../../interfaces/ErrorApiResponse";


export const useRecord = (id: number) => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState<Record>();
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);


    useEffect(() => {
        setLoading(true);
        const handleRecord = async () => {
            setTimeout(async () => {
                await axios.get<Record>(`${process.env.REACT_APP_API_URL}/records/${id}`, { params: { token: userData?.token } })
                    .then((response) => {
                        setRecord(response.data);
                        setErrors(false);
                    })
                    .catch((error) => {
                        if (axios.isAxiosError(error)) {
                            const err = error as AxiosError;

                            if (!err.response) {
                                setErrors({ message: "Error al tratar de contactar con el servidor" });
                            } else {
                                setErrors(err.response.data as ErrorApiResponse);
                            }
                        }
                    })
                    .finally(() => setLoading(false));
            }, 2000)
        }

        handleRecord();

        return () => {
            setRecord(undefined);
            setLoading(false);
            setErrors(false);
        }
    }, [id, userData?.token])


    return { record, loading, errors }
}
