import { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Spinner } from '../../../components/Spinner';

import { useForm } from '../../../hooks/utils/useForm';
import { useLogin } from "../../../hooks/user/useLogin";

import styles from "./styles/main.module.scss";

interface InitialFormProps {
  email: string,
  password: string
}


const initialForm: InitialFormProps = {
  email: "",
  password: ""
}

export const Login = () => {
  const { form, handleBlur, handleChange } = useForm(initialForm);
  const { login, errors: useLoginErrors, loading: useLoginLoading } = useLogin();

  const [showModal, setShowModal] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    await login(form.email, form.password);
  }

  useEffect(() => {
    setShowModal(!!useLoginErrors);

    return () => {
      setShowModal(false)
    }
  }, [useLoginErrors]);

  // Titulo del tab
  useEffect(() => {
    document.title = `Inicio de sesion - ${process.env.REACT_APP_TITLE_PAGE}`
  }, []);

  if (useLoginLoading) {
    return <Spinner />
  }

  return (
    <div className="d-flex justify-content-center">
      <div className={`${styles.form_container}`}>
        <Form className={`bg24 rounded mt-5 p-5`} onSubmit={handleLogin}>
          <h3 className="mb-4 text-center">Login para Mapas</h3>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Correo Electronico</Form.Label>
            <Form.Control name="email" type="email" placeholder="example@example.com" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.email} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control name="password" type="password" placeholder="Password" onChange={(e) => handleChange(e.target.name, e.target.value)} onBlur={(e) => handleBlur(e.target.name, e.target.value)} value={form.password} />
          </Form.Group>

          <FormGroup className="mb-3 text-center">
            <p className="text-muted mb-0">
              No tienes una cuenta? Registrate!
            </p>

            <Link to={"/register"} className="text-center">Crea una cuenta!</Link>
          </FormGroup>
          <Button variant="danger" type="submit" className="w-100" disabled={useLoginLoading}>
            Iniciar sesion
          </Button>
        </Form>

        {!!useLoginErrors && (
          <Modal show={showModal} onHide={() => setShowModal(false)} centered={true} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>No se ha podido iniciar sesion</Modal.Title>
            </Modal.Header>
            <Modal.Body>{useLoginErrors.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Aceptar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div >
  );
};
