import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserState, UserData } from "../interfaces/UserState";


const createInitialState = (): UserState => {
  const userCookie = localStorage.getItem('session');

  if (userCookie) {
    return JSON.parse(userCookie);
  }

  return { logged: false, userData: null }
}

export const userSlice = createSlice({
  name: "user",
  initialState: createInitialState(),
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      const { logged, userData } = action.payload;
      const { token } = userData as UserData;

      state.logged = logged;
      state.userData = { token };

      localStorage.setItem('session', JSON.stringify(state));
    },

    logout: (state) => {
      state.logged = false;
      state.userData = null;

      localStorage.removeItem('session');
    },
  },
});

export default userSlice.reducer;
export const { login, logout } = userSlice.actions;
