import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';
import { UserData } from '../../interfaces/UserState';

import { login as storeLogin } from "../../store/userSlice"

export const useLogin = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);

    const login = async (email: string, password: string) => {
        setLoading(true);
        setErrors(false);

        setTimeout(async () => {
            await axios.post<UserData>(`${process.env.REACT_APP_API_URL}/users/login`, { email, password })
                .then((response) => {
                    const { data } = response;
                    dispatch(storeLogin({ logged: true, userData: data }));
                }).catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse)
                        }
                    }
                }).finally(() => setLoading(false))
        }, 2000)

    }

    return { loading, errors, login }
}
