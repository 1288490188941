import React, { useEffect, useState } from 'react';
import { Marker, Polygon } from '@react-google-maps/api';
import { useSearchParams } from 'react-router-dom';

import { Map } from "../../../components/Map";
import { RecordList } from '../../../components/RecordList';
import { Searchbar } from '../../../components/Searchbar';
import { Spinner } from '../../../components/Spinner';

import { useRecords } from '../../../hooks/record/useRecords';
import { useRecordTypes } from '../../../hooks/recordTypes/useRecordTypes';

import { Record } from '../../../interfaces/Record';

import pin_image from "../../../assets/pin-mapa.png";

import switch_styles from '../../../styles/components/_switchPolygon.module.scss';

export const Dashboard = () => {
  const { records, recordsQuery, recordsInsidePolygon, loading: useRecordsLoading, handleQuery, handleInsidePoly } = useRecords();
  const { recordTypes, loading: useRecordTypesLoading } = useRecordTypes();
  const [searchParams, setSearchParams] = useSearchParams();

  const [polygonPath, setPolygonPath] = useState<google.maps.LatLngLiteral[] | google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[]>([
    { lat: 25.531598, lng: -103.403717 },
    { lat: 25.561598, lng: -103.403717 },
    { lat: 25.561598, lng: -103.353717 },
    { lat: 25.531598, lng: -103.353717 },
  ]);
  const [polygon, setPolygon] = useState<google.maps.Polygon>();
  const [isPolygon, setIsPolygon] = useState(false);

  const [showAll, setShowAll] = useState(false);

  const [filter, setFilter] = useState<'all' | 'polygon' | 'query'>('all')

  const handleRecordsInsidePoly = () => {
    if (!polygon) return;

    setPolygonPath(polygon.getPath());

    handleInsidePoly(polygon);
  }

  useEffect(() => {
    if (showAll) {
      setFilter('all');
      return;
    }

    if (isPolygon) {
      setFilter('polygon');
    } else {
      setFilter('query');
    }

    return () => {
    }
  }, [isPolygon, showAll]);


  useEffect(() => {
    if (!polygon || !isPolygon) return;

    setTimeout(() => {
      setPolygonPath(polygon.getPath());

      handleInsidePoly(polygon);
    }, 1200);

    return () => {
    }
  }, [handleInsidePoly, isPolygon, polygon]);


  useEffect(() => {
    if (isPolygon) {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);

    return () => {

    }
  }, [isPolygon, searchParams, setSearchParams])

  // useEffect para el leer el contenido de search en el url y aplicar el filtro en los registros
  useEffect(() => {
    if (recordTypes && records) {

      let match = searchParams.get('search');

      if (!match) match = "";

      handleQuery(match, recordTypes);
    }

    return () => {

    }
  }, [handleQuery, recordTypes, records, searchParams]);

  // Titulo del tab
  useEffect(() => {
    document.title = `Inicio - ${process.env.REACT_APP_TITLE_PAGE}`;
  }, []);


  if (useRecordsLoading || useRecordTypesLoading) {
    return <Spinner />
  }

  return (
    <>
      {!isPolygon &&
        <Searchbar placeholder='Buscar registro' />
      }

      <div className='d-flex justify-content-end'>
        <div className={`${switch_styles.switch} mx-2 my-3 form-switch-md form-switch`}>
          <input
            type="checkbox"
            className={`${isPolygon ? `${switch_styles.checked}` : `${switch_styles.unchecked}`} form-check-input`}
            onChange={(e) => setIsPolygon(e.target.checked)}
          />
        </div>
      </div>

      <div className='w-100 mb-4' style={{ height: '500px' }}>
        <Map>
          {isPolygon &&
            <Polygon
              path={polygonPath}
              visible
              draggable
              editable
              onLoad={(e) => { setPolygon(e); }}
              onDragEnd={() => handleRecordsInsidePoly()}
            />
          }

          {(searchParams.get('search') && !isPolygon) ?
            recordsQuery.map(({ id, location_lat, location_lng }) =>
              <Marker key={`marker${id}`} position={{ lat: location_lat, lng: location_lng }} icon={pin_image} />)
            :
            records.map(({ id, location_lat, location_lng }) =>
              <Marker key={`marker${id}`} position={{ lat: location_lat, lng: location_lng }} icon={pin_image} />)
          }
        </Map>
      </div>

      <div className='d-flex justify-content-end'>
        <div className={`${switch_styles.switch} mx-2 mb-3 form-switch-md form-switch`}>
          <input
            type="checkbox"
            className={`form-check-input`}
            onChange={(e) => setShowAll(e.target.checked)}
          />
        </div>
      </div>

      <RecordListSelected
        allRecords={records}
        filter={filter}
        recordsQuery={recordsQuery}
        recordsInsidePolygon={recordsInsidePolygon}
      />

    </>
  );
};

interface RecordListSelectedProps {
  allRecords: Record[],
  recordsQuery: Record[],
  recordsInsidePolygon: Record[]
  filter: 'all' | 'polygon' | 'query'
}

const RecordListSelected = ({ filter, allRecords, recordsQuery, recordsInsidePolygon }: RecordListSelectedProps) => {
  switch (filter) {
    case 'all':
      return <RecordList records={allRecords} totalRecords={allRecords.length} />

    case 'polygon':
      return <RecordList records={recordsInsidePolygon} totalRecords={allRecords.length} />

    case 'query':
      return <RecordList records={recordsQuery} totalRecords={allRecords.length} />

    default:
      return <RecordList records={allRecords} totalRecords={allRecords.length} />
  }
}