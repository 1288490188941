import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { ErrorApiResponse } from '../../interfaces/ErrorApiResponse';
import { RecordType } from '../../interfaces/RecordType';

import { RootState } from '../../store/store';

export const useRecordType = (id: number) => {
    const {
        user: { userData },
    } = useSelector((state: RootState) => state);

    const [recordType, setRecordType] = useState<RecordType>();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<ErrorApiResponse | false>(false);

    useEffect(() => {
        setLoading(true);

        const handleRecordType = async () => {
            await axios.get<RecordType>(`${process.env.REACT_APP_API_URL}/record-types/${id}`, { params: { token: userData?.token } })
                .then((response) => {
                    setRecordType(response.data);
                    setErrors(false);
                })
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        const err = error as AxiosError;

                        if (!err.response) {
                            setErrors({ message: "Error al tratar de contactar con el servidor" });
                        } else {
                            setErrors(err.response.data as ErrorApiResponse);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }

        if (!isNaN(id)) {
            handleRecordType();
        }

        return () => {
            setRecordType(undefined);
            setLoading(false);
            setErrors(false);
        }
    }, [id, userData?.token])


    return { recordType, loading, errors }
}
